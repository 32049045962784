@import "../../../../styles/vars";

.preferencesCheckboxes {
  display: flex;
  margin-top: 10px;
  @media (max-width: $tablet-width) {
    margin-top: 5px;
    flex-direction: column;
  }
}

.checkboxWrapper {
  margin-right: 15px;
  @media (max-width: $tablet-width) {
    margin-right: 0;
    margin-top: 5px;
  }
}
