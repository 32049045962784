@import "../../../../styles/vars";

.wrapper {
  min-width: 400px;
  @media (max-width: $tablet-width) {
    min-width: initial;
  }
}

.bannerTitle {
  margin-bottom: 15px;
  font-size: 22px;
  font-family: $font-oswald;
}

.policyLink {
  text-decoration: underline;
}

.buttonWrapper {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;

  @media (max-width: $tablet-width) {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 10px;
  }

  button {
    &:hover {
      transform: none;
      filter: brightness(1.1);
    }
  }
}
